import styled from "styled-components";

export const HeaderLogoContainer = styled.div`
    font-size: 14px;
    line-height: 1.4;
    display: block;
    margin: 0;
    box-sizing: border-box;
    float: left;


    @media only screen and (min-width: 0) {
        padding-left: 6px;
        padding-right: 6px;
        width: 50%;
        order: 1;
    }

    @media only screen and (min-width: 750px) {
        padding-left: 12px;
        padding-right: 12px;
        width: 43.33333%;
    }

    @media only screen and (min-width: 900px) {
        padding-left: 18px;
        padding-right: 18px;
        width: 33.33333%;
    }

    @media only screen and (min-width: 1200px){
        width: 25%;
    }
`