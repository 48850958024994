import React from 'react'
import styled from "styled-components"
import logo from '../../assets/logo.svg'
import { HeaderLogoContainer } from '../styles/HeaderLogoContainer.styled'
import { HeaderLogoWrapper } from '../styles/HeaderLogoWrapper.styled'
import { HeaderLogoAnchor } from '../styles/HeaderLogoAnchor.styled'

const LogoAnchor = styled.a`
    box-sizing: border-box;
    display: block;
    margin-right: 10px;
    
`

const Logo = styled.img`
    width: 200px;
    // max-width: fit-content;
`

export default function HeaderLogo() {
    return (
        <HeaderLogoContainer>
            <HeaderLogoWrapper>
                <LogoAnchor href="https://essentialwholesale.com/">
                    <Logo src={logo}/>
                </LogoAnchor>
                <HeaderLogoAnchor href="/">Blog&nbsp;Home</HeaderLogoAnchor>
            </HeaderLogoWrapper>
        </HeaderLogoContainer>
    )
}
