import styled from "styled-components";

export const FooterStyle = styled.footer`
color: #546E7A;
font-family: "HurmeGeoSans", sans-serif;
font-size: 1.2rem;
letter-spacing: 0.75px;
line-height: 1.5;
padding: 64px 0;
position: relative;
text-align: center;
text-transform: uppercase;

&:before {
    background-color: #CFD8DC;
    content: '';
    display: block;
    height: 1px;
    margin: 0 auto 64px;
    width: 150px;
}

&:after {
    clear: both;
    content: " ";
    display: table;
}
`