import React from 'react'
import styled from 'styled-components'

const ShopWrap = styled.div`
float: left;
box-sizing: border-box;
margin: 0;

@media only screen and (min-width: 0) {
    padding-right: 6px;
    width: 50%;
    order: 2;
}

@media only screen and (min-width: 750px) {
    padding-left: 12px;
    padding-right: 12px;
    width: 15%;
    order: 3;
}

@media only screen and (min-width: 900px) {
    padding-left: 18px;
    padding-right: 18px;
    width: 15%;
}

@media only screen and (min-width: 1200px) {
    width: 15%;
}
`

const PStyled = styled.p`
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    box-sizing: border-box;
    margin: 0;
    font-weight: 500;
    color: #00663e;
    font-size: 1.8rem;
    
    @media only screen and (min-width: 0) {
        text-align: right;
        margin-top: 6px;
    }

    @media only screen and (min-width: 480px) {
        margin: 12px;
        margin-top: 4px;
    }

    @media only screen and (min-width: 750px) {
        margin: 0px;
    }

    @media only screen and (min-width: 0px) and (max-width: 479px) {
        font-size: 1.4rem;
        margin-top: 1px;
    }
`

const AStyled = styled.a`
    text-decoration: none;
    color: #00663e;

    &:hover {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        color: #00663e;
    }
`

export default function HeaderShopLink() {
    return (
        <ShopWrap>
            <PStyled>
                <AStyled href="https://essentialwholesale.com">
                    SHOP
                    <svg width="24" fill="#00663e" xmlns="http://www.w3.org/2000/svg" viewBox="1 -7 24 24">
                        <path d="M10,17a1,1,0,0,1-.707-1.707L12.586,12,9.293,8.707a1,1,0,0,1,1.414-1.414L15.414,12l-4.707,4.707A1,1,0,0,1,10,17Z"></path>
                    </svg>
                </AStyled>
            </PStyled>
        </ShopWrap>
    )
}
