import styled from 'styled-components'

export const SiteWrapper = styled.div`
    display: block;
    overflow: hidden;
    position: relative;
    font-size: 2rem;

    &:before {
        background-color: #F9F9F9;
        content: '';
        // min-height: 450px;
        min-height: 350px;
        left: 0px;
        position: absolute;
        bottom: 0px;
        width: 100%;
        z-index: -5;
    }

    // &:after {
    //     background-color: #fff;
    //     content: '';
    //     height: 1000px;
    //     // right: -1050px;
    //     right: -950px;
    //     position: absolute;
    //     bottom: 0px;
    //     transform: rotate(60deg);
    //     width: 100%;
    //     z-index: -5;
    // }
`