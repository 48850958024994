import styled from 'styled-components'

export const SiteContainer = styled.div`
    -webkit-animation: fadein 1s;
    animation: fadein 1s;
    word-wrap: break-word;

    &:after {
        clear: both;
        content: " ";
        display: table;
        box-sizing: inherit;
    }

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    @-webkit-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
`