import styled from "styled-components";
import logo from '../../assets/logo.svg'


export const HeaderLogoWrapper = styled.div`
    font-size: 22px;
    line-height: 0.75;
    box-sizing: border-box;
    // background-image: url(${logo});
    background-repeat: no-repeat;
    display: flex;
    justify-content: start;
    height: 30px;
    // text-indent: 157px;
    background-size: 143px;
    width: 100%;
    letter-spacing: -0.5px;


    @media only screen and (min-width: 0) {
        margin: 12px;
    }


    @media only screen and (min-width: 750px) {
        margin: 0px;
        margin-top: 6px;
    }

    @media only screen and (min-width: 0px) and (max-width: 479px) {
        text-indent: 20vw;
        background-size: 107px;
        width: 100%;
        line-height: 0.65;
        display: flex;
        flex-wrap: wrap;
        height: 62px;
    }

    
`