import styled from 'styled-components'

export const Wrap = styled.div`
    &:before {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
        content: " ";
        display: table;
    }

    max-width: 1200px;
	margin-left: auto;
	margin-right: auto;

    @media only screen and (max-width: 1360px) {
        max-width: 1140px;
    }

    @media only screen and (max-width: 1250px) {
        max-width: 960px;
    }

    @media only screen and (max-width: 1075px) {
        padding-left: 5%;
		padding-right: 5%;
		max-width: none;
    }
`