import React from "react"
import { SiteHeader } from '../styles/SiteHeader.styled'
import HeaderLogo from './HeaderLogo'
import {SearchWrapper} from '../styles/SearchWrapper.styled'
import SearchForm from "../search/search-form"
import HeaderShopLink from './HeaderShopLink'

export default function Header() {
    return (
        <SiteHeader>
                <HeaderLogo />
                <SearchWrapper>
                    <SearchForm />
                </SearchWrapper>
                <HeaderShopLink />
        </SiteHeader>
    )
}
