import styled from 'styled-components'

export const SiteHeader = styled.header`
    &:before {
        content: " ";
	    display: table;
    }

    &:after {
        clear: both;
        content: " ";
        display: table;
    }

    background: none;
    background-color: #fff;

    @media only screen and (min-width: 0) {
        padding-top: 6px;
        padding-bottom: 6px;
        border-bottom: 1px solid #E1E3DF;
    }

    @media only screen and (min-width: 480px) {
        padding-top: 9px;
        padding-bottom: 18px;
    }

    @media only screen and (min-width: 750px) {
        padding-top: 18px;
        flex-wrap: none;
    }

    @media only screen and (max-width: 749px) {
        display: flex;
        flex-wrap: wrap;
    }
`