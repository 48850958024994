import styled from "styled-components"


const theme = {
    foreground: "#050505",
    background: "#F6F6F6",
    faded: "#888",
  }


export const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;

  // Accessible search bar
  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .SearchInput {
    width: 100%;
    outline: none;
    border: auto;
    font-size: 1em;
    transition: 100ms;
    appearance: none;
    border-color: rgba(0, 0, 0, 0.15);
    border-top-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    color: #222;
    display: block;
    font-family: 'HurmeGeoSans', 'Lato', 'Roboto', sans-serif, serif;
    font-size: 16px;
    height: 22px;
    outline: none;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 12px;
    padding-right: 12px;
    z-index: 1;
    color: ${theme.foreground};
    ::placeholder {
      color: ${theme.faded};
    }

    &:hover {
      border-color: #adaeaa;
    }
    &:focus {
      border-color: #777;
      cursor: text;
    }
  }

  .SearchIcon {
    width: 1em;
    margin: 0.3em;
    color: ${theme.foreground};
    pointer-events: none;
    color: #00663e;
  }

  .SearchButton {
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.15);
    border-top-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    color: #222;
    display: block;
    height: 42px;
    outline: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    margin-left: -1px;
    z-index: 0;

    &:hover {
      background: ${theme.background};
    }
  }
`