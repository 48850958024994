import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { SiteBodyWrapper } from '../styles/SiteBodyWrapper.styled'
import { SiteWrapper } from '../styles/SiteWrapper.styled'
import { SiteContainer } from '../styles/SiteContainer.styled'
import Header from './Header'
import Footer from './Footer'
// import parse from "html-react-parser"

const Layout = ({ isHomePage, children }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      <SiteBodyWrapper>
        <SiteWrapper>
          <SiteContainer>
            <Header />
            {children}
            <Footer />
          </SiteContainer>
        </SiteWrapper>
    </SiteBodyWrapper>
    </div>
  )
}

export default Layout
