import styled from "styled-components";

export const NavSecondary = styled.nav`
margin-top: 10px;

&:before {
    content: " ";
    display: table;
}

&:after {
    clear: both;
    content: " ";
    display: table;
}
`