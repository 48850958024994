import React, { useState } from "react"
import { Search as SearchIcon } from "@styled-icons/fa-solid"
import { StyledForm } from "./styled-search"
import { Redirect } from "react-router-dom"

const SearchForm = () => {
  const [value, setValue] = useState("")
  const [searchTerm, setSearchTerm] = useState("")
  const handleSubmit = () => {
    setSearchTerm(value);
  }
  return (
      <>
      <StyledForm className="SearchForm" role="search" onSubmit={handleSubmit}>
      <label htmlFor="header-search">
            <span className="visually-hidden">
                Search blog posts
            </span>
        </label>
      <input
        className="SearchInput"
        type="text"
        placeholder="Search the Blog"
        aria-label="Search"
        name="s"
        id="header-search"
        minLength="2"
        maxLength="257"
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <button type="submit"
        value="Search"
        aria-label="Search"
        className="SearchButton">
          <SearchIcon className="SearchIcon" />
        </button>
    </StyledForm>
    <div id='redirect'></div>
     {searchTerm.length > 0 &&
      <Redirect to={{
          pathname: '/search/',
          search: ("s=" + searchTerm.toLowerCase()),
      }}/>
      }
    </>
  )
}
export default SearchForm