import styled from "styled-components";

export const HeaderLogoAnchor = styled.a`
    cursor: pointer;
    background: transparent;
    text-decoration: none;
    font-family: "HurmeGeoSans","Lato","Roboto",sans-serif,serif;
    color: #6b6b6b;
    font-weight: 400;
    font-size: 28px;
    padding-top: 2px;
    width: 100%;
    margin: 0;
    overflow-wrap: normal;

    &:hover {
        color: #686868;
        cursor: pointer;
        text-decoration: underline;
    }


    @media only screen and (min-width: 0px) and (max-width: 479px) {
        font-size: 20px;
        padding-top: 2px;
        width: 100%;
        margin: 0;
        padding-left: 55px;
        margin-top: 7px;
    }
`