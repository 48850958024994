import React from "react"

const liStyle = {
  float: "none",
  position: "relative",
  display: "inline-block",
}

const aStyle = {
  fontSize: "1.4rem",
  fontWeight: "400",
  outlineOffset: "-1px",
  color: "#546E7A",
  display: "block",
  marginLeft: "10px",
  marginRight: "10px",
  padding: "0",
  textDecoration: "none",
}

export default function FooterNavLink({ link, text }) {
  return (
    <li style={liStyle}>
      <a href={link} style={aStyle} itemProp="url">
        <span itemProp="name">{text}</span>
      </a>
    </li>
  )
}
