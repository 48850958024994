import styled from "styled-components";

export const SearchWrapper = styled.div`
float: left;
box-sizing: border-box;
margin: 0;

@media only screen and (min-width: 0) {
    padding-left: 6px;
    padding-right: 6px;
}

@media only screen and (max-width: 749px) {
    order: 3;
    margin-left: 24px;
    margin-right: 12px;
    width: 100%;
}

@media only screen and (min-width: 750px) {
    padding-left: 12px;
    padding-right: 12px;
    width: 41.66667%;
    order: 2;
}

@media only screen and (min-width: 900px) {
    padding-left: 18px;
    padding-right: 18px;
    width: 51.66667%;
}

@media only screen and (min-width: 1200px) {
    width: 60%;
}

`