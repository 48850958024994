import React from "react"
import { FooterStyle } from '../styles/FooterStyle.styled'
import { Wrap } from '../styles/Wrap.styled'
import { NavSecondary } from '../styles/NavSecondary.styled'
import FooterNavLink from './FooterNavLink'
import styled from "styled-components"

const pStyle = {
    marginBottom: 0,
}

const ulStyle = {
    clear: 'both',
    width: '100%',
    lineHeight: '1.5',
    marginBottom: 0,
}

const ColorBlock = styled.div`
    width: 100%;
    background-color: #00673f;
    
    @media only screen and (min-width: 0px) {
        height: 36px;
        margin: 48px 0 36px 0;
    }

    @media only screen and (min-width: 880px) {
        height: 96px;
    }
`



export default function Footer() {
    return (
        <>
        <ColorBlock />
        <FooterStyle itemScope="" itemType="https://schema.org/WPFooter">
            <Wrap>
                <p style={pStyle}>Copyright © {new Date().getFullYear()} Essential Wholesale & Labs</p>
                <NavSecondary itemScope="" itemType="https://schema.org/SiteNavigationElement">
                    <Wrap>
                        <ul style={ulStyle} id="menu-footer-navigation">
                            <FooterNavLink link='https://essentialwholesale.com/about-us/' text='About'/>
                            <FooterNavLink link='https://essentialwholesale.com/contact/' text='Contact'/>
                            <FooterNavLink link='https://essentialwholesale.com/privacy-policy/' text='Privacy'/>
                            <FooterNavLink link='https://essentialwholesale.com/terms-conditions/' text='Terms'/>
                            <FooterNavLink link='https://essentialwholesale.com/' text='Shop'/>
                        </ul>
                    </Wrap>
                </NavSecondary>
            </Wrap>
        </FooterStyle>
        </>
    )
}
